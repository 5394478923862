<template>
  <c-box flex-grow="1">
    <c-breadcrumb
      fontSize="14px"
      lineHeight="21px"
      separator="›"
      :d="['none', 'block']"
    >
      <c-breadcrumb-item>
        <c-breadcrumb-link isCurrent> Manajemen Ahli Gizi </c-breadcrumb-link>
      </c-breadcrumb-item>
    </c-breadcrumb>
    <c-divider my="16px" />

    <c-flex mb="33px" justify="space-between" align="center">
      <c-heading
        as="h3"
        font-family="Montserrat"
        font-weight="bold"
        font-size="24px"
        line-height="36px"
      >
        Manajemen Ahli Gizi
      </c-heading>
      <c-button
        h="auto"
        py="12px"
        px="28px"
        color="#927102"
        borderRadius="24px"
        border-color="transparent"
        bg="secondary.800"
        variant-color="inherit"
        fontSize="16px"
        lineHeight="24px"
        variant="solid"
        as="router-link"
        :to="{ name: 'superadmin.nutritionists.add' }"
      >
        <c-image
          :src="require('@/assets/icon-plus.svg')"
          alt="icon"
          display="inline-block"
          mr="10px"
        />
        Tambah Ahli Gizi
      </c-button>
    </c-flex>
    <c-box
      background="#FFFFFF"
      border="1px solid #F2F2F2"
      box-shadow="4px 4px 50px 5px rgba(0, 0, 0, 0.05)"
      border-radius="12px"
      class="container"
    >
      <c-flex py="25px" px="27px" border-bottom="1px solid #C4C4C4">
        <c-flex justify="space-between" align="center" flex-grow="1">
          <c-heading
            as="h3"
            font-family="Montserrat"
            font-weight="600"
            font-size="16px"
            line-height="24px"
          >
            List of Ahli Gizi
          </c-heading>
          <c-button
            borderRadius="40px"
            backgroundColor="superLightGray.900"
            pl="20px"
            pr="30px"
            py="10px"
            h="auto"
            color="gray.900"
            fontSize="14px"
            lineHeight="21px"
            fontFamily="Roboto"
            fontWeight="normal"
            display="flex"
            @click="onExportFile"
          >
            <c-image
              :src="require('@/assets/icon-file-export.svg')"
              alt="icon"
              display="inline-block"
              marginRight="10px"
            />
            Export
          </c-button>
        </c-flex>
        <c-divider mx="23px" orientation="vertical" />
        <c-box flexBasis="248px" alignSelf="center">
          <c-form-control pos="relative" w="100%">
            <c-image
              :src="require('@/assets/ic-search.svg')"
              alt="icon"
              pos="absolute"
              top="10px"
              left="15px"
              zIndex="2"
            />
            <c-input
              type="text"
              w="100%"
              id="search"
              placeholder="Pencarian"
              background="superLightGray.900"
              borderRadius="6px"
              paddingLeft="50px"
              pos="relative"
              zIndex="1"
              v-chakra="{
                '&::placeholder': {
                  color: 'gray.900',
                },
              }"
              @keyup="onSearch"
              v-model="search"
            />
          </c-form-control>
        </c-box>
      </c-flex>

      <c-box>
        <c-box>
          <c-grid
            w="100%"
            :template-columns="'repeat(' + headings.length + ', 0fr)'"
          >
            <c-box
              v-for="(item, index) in headings"
              :key="'heading-' + index"
              :w="item.width"
              py="11px"
              px="14px"
              color="brand.900"
              font-weight="bold"
              font-size="12px"
              line-height="18px"
              textTransform="uppercase"
              bg="rgba(0, 140, 129, 0.1)"
            >
              {{ item.label }}
            </c-box>
          </c-grid>

          <c-grid
            w="100%"
            max-w="100%"
            :template-columns="'repeat(' + headings.length + ', 0fr)'"
            border-bottom="1px solid #C4C4C4"
            v-for="(item, index) in items"
            :key="item.id"
          >
            <c-box
              :w="headings[0].width"
              py="16px"
              px="14px"
              color="black.900"
              font-weight="normal"
              font-size="14px"
              line-height="21px"
              d="flex"
              align-items="center"
            >
              {{ numberingRow(index) }}
            </c-box>
            <c-box
              :w="headings[1].width"
              py="16px"
              px="14px"
              color="black.900"
              font-weight="normal"
              font-size="14px"
              line-height="21px"
              d="flex"
              align-items="center"
            >
              {{ item.email }}
            </c-box>
            <c-box
              :w="headings[2].width"
              py="16px"
              px="14px"
              color="black.900"
              font-weight="normal"
              font-size="14px"
              line-height="21px"
              d="flex"
              align-items="center"
            >
              {{ item.firstName }}
            </c-box>
            <c-box
              :w="headings[3].width"
              py="16px"
              px="14px"
              color="black.900"
              font-weight="normal"
              font-size="14px"
              line-height="21px"
              d="flex"
              align-items="center"
            >
              {{ item.lastName }}
            </c-box>
            <c-box
              :w="headings[4].width"
              py="16px"
              px="14px"
              color="black.900"
              font-weight="normal"
              font-size="14px"
              line-height="21px"
              d="flex"
              align-items="center"
            >
              {{ item.quota }}
            </c-box>
            <c-box
              :w="headings[5].width"
              py="16px"
              px="14px"
              color="black.900"
              font-weight="normal"
              font-size="14px"
              line-height="21px"
              d="flex"
              align-items="center"
            >
              {{ item.quota }}
            </c-box>
            <c-box
              :w="headings[6].width"
              py="16px"
              px="14px"
              color="black.900"
              font-weight="normal"
              font-size="14px"
              line-height="21px"
              d="flex"
              align-items="center"
            >
              <c-button
                h="30px"
                color="brand.900"
                borderRadius="24px"
                border-color="brand.900"
                fontSize="12px"
                lineHeight="18px"
                variant="outline"
                as="router-link"
                :to="{
                  name: 'superadmin.nutritionists.detail',
                  params: { id: item.id },
                }"
              >
                Lihat
              </c-button>
            </c-box>
          </c-grid>
          <c-flex v-if="!isEmpty" justify="space-between" py="28px" px="40px">
            <c-flex align="center">
              <c-text color="gray.900" fontSize="14px" lineHeight="21px">
                Show:
              </c-text>
              <c-select
                @change="onSearch"
                v-model="perPage"
                min-w="120px"
                border-radius="6px"
                fontSize="14px"
                ml="13px"
                size="md"
              >
                <option value="5">5 Rows</option>
                <option value="10">10 Rows</option>
                <option value="15">15 Rows</option>
                <option value="20">20 Rows</option>
              </c-select>
            </c-flex>

            <c-flex justifyContent="flex-end" w="100%">
              <c-flex justifyContent="center" align="center">
                <c-button
                  p="0"
                  mr="16px"
                  w="32px"
                  h="32px"
                  min-w="32px"
                  variant="outline"
                  borderRadius="30px"
                  backgroundColor="transparent"
                  :isDisabled="currentPage === 1"
                  @click="prevPage"
                >
                  <c-icon name="chevron-left" size="24px" />
                </c-button>

                <c-button
                  p="0"
                  m="0"
                  w="32px"
                  h="32px"
                  min-w="32px"
                  mx="4px"
                  borderRadius="30px"
                  backgroundColor="rgba(0, 140, 129, 0.3)"
                  color="brand.900"
                  v-chakra="{
                    ':hover': {
                      bg: 'rgba(0, 140, 129, 0.3)',
                      color: 'brand.900',
                    },
                  }"
                  v-for="page in pages"
                  :key="page"
                  :bg="
                    page === currentPage
                      ? ['rgba(0, 140, 129, 0.3)']
                      : ['transparent']
                  "
                  as="router-link"
                  :to="{ name: $route.name, query: { ...$route.query, page } }"
                >
                  {{ page }}
                </c-button>

                <c-button
                  p="0"
                  ml="16px"
                  w="32px"
                  h="32px"
                  min-w="32px"
                  variant="outline"
                  borderRadius="30px"
                  backgroundColor="transparent"
                  :isDisabled="pages.length === page"
                  @click="nextPage"
                >
                  <c-icon name="chevron-right" size="24px" />
                </c-button>
              </c-flex>
            </c-flex>
          </c-flex>
          <c-flex v-if="isEmpty" py="80px" px="20px" justifyContent="center">
            <c-box>
              <c-image
                :src="require('@/assets/empty-nutritionists.svg')"
                alt="empty"
              />
              <c-text
                color="gray.900"
                fontFamily="Roboto"
                fontSize="24px"
                marginTop="20px"
              >
                Ahli Gizi tidak ditemukan
              </c-text>
            </c-box>
          </c-flex>
        </c-box>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";
import generalMixin from "@/utils/general-mixins";

export default {
  name: "ManagementNutritionistsPage",
  mixins: [generalMixin],
  components: {},
  data() {
    return {
      headings: [
        { label: "no", width: "76px" },
        { label: "email", width: "226px" },
        { label: "nama depan", width: "190px" },
        { label: "nama belakang", width: "173px" },
        { label: "quota", width: "70px" },
        { label: "rating", width: "70px" },
        { label: "action", width: "90px" },
      ],
      page: 1,
      perPage: "10",
      search: this.$route.query.q ?? "",
      startDate: "",
      endDate: "",
      timer: null,
    };
  },
  computed: {
    ...mapState({}),
    ...mapGetters({
      items: "suNutritionists/items",
      meta: "suNutritionists/meta",
    }),
    isEmpty() {
      return this.items.length === 0;
    },
    currentPage() {
      return parseInt(this.$route.query.page ?? 1, 10);
    },
    pages() {
      let total = this.meta.total ?? 1;
      let perPage = parseInt(this.perPage) ?? 10;
      let length = Math.ceil(total / perPage);
      return Array.from({ length }, (_, idx) => idx + 1);
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        this.page = route.query.page ? parseInt(route.query.page) : 1;
        let params = new URLSearchParams();
        params.set("q", this.search);
        params.set("startAt", this.startDate);
        params.set("endAt", this.endDate);
        params.set("page", this.page);
        params.set("perpage", this.perPage);
        this.loadNutritionists(params);
      },
    },
  },
  methods: {
    ...mapActions({
      loadNutritionists: "suNutritionists/listNutritionists",
      exportFileNutritionists: "suNutritionists/exportFileNutritionists",
    }),
    onExportFile() {
      this.exportFileNutritionists()
        .then((res) => {
          var blob = new Blob([res], { type: "application/vnd.ms-excel" });
          var downloadElement = document.createElement("a");
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = "List Ahli Gizi.xlsx";
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        })
        .catch((err) => {
          this.$toast({
            title: "Failed",
            description: err.data.message ?? "Ops! Something when wrong.",
            status: "error",
            duration: 5000,
            position: "bottom-right",
            variant: "subtle",
          });
        });
    },
    onSearch() {
      this.page = 1;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.$router.replace({
          name: this.$route.name,
          query: {
            q: this.search,
            startAt: this.startDate,
            endAt: this.endDate,
            page: this.page,
            perpage: this.perPage,
          },
        });
      }, 800);
    },
    numberingRow(index) {
      return this.page * this.perPage - this.perPage + index + 1;
    },
    prevPage() {
      this.page -= 1;
      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, page: this.page },
      });
    },
    nextPage() {
      this.page += 1;
      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, page: this.page },
      });
    },
  },
};
</script>